import logo from './logo.svg';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

const accountExecutives = [
                            "Playfly", "Lisa Vanderberg", "Debra Taylor", "Randy Hoffman", 
                            "Robert DeNinnis", "Lorene Mallis", "John Mullins", "Nicole Phillips", 
                            "Marie Wright-Cyrankowski", "Vegas Golden Knights"
                          ];

function App() {
  const [ktnvForm, setKtnvForm] = useState({});
  const [validated, setValidated] = useState(false);

  const updateKtnvForm = (e) => {
    setKtnvForm({
      ...ktnvForm,
      [e.target.name] : e.target.value
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    Axios.post('/vegas34/index.php', ktnvForm).then((response) => {
      console.log(response);
    });
  }

  return (
    <div className="App">
        <Container>
          <Row className="header">
            <Col>
              <img src="/vegas-34.png" />
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col md xs={12}>
              <Form.Control
                name="name"
                type="text"
                placeholder="Name"
                value={ktnvForm?.name}
                onChange={updateKtnvForm}
              />
            </Col>
            <Col md xs={12}>
              <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                value={ktnvForm?.email}
                onChange={updateKtnvForm}
              />
            </Col>
          </Row>
          <Row>
          <Col>
              <Form.Control
                name="address"
                type="text"
                placeholder="Address"
                value={ktnvForm?.address}
                onChange={updateKtnvForm}
              />
            </Col>
          </Row>
          <Row>
            <Col md xs={12}>    
              <Form.Select name="guests" onChange={updateKtnvForm} value={ktnvForm.guests ? ktnvForm.guests: "Guests"}>
                <option disabled value="Guests">Guests</option>
                <option value="1">One</option>
                <option value="2">Two</option>
              </Form.Select>
            </Col>
            <Col md xs={12}>    
              <Form.Select name="ae" onChange={updateKtnvForm} value={ktnvForm.ae ? ktnvForm.ae : "Account Executive"}>
                <option disabled value="Account Executive">Account Executive</option>
                {accountExecutives.map((executive) => {
                  return <option value={executive}>{executive}</option>
                })}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" disables={!validated}>Submit</Button>
            </Col>
          </Row>
          <Row className="footer">
            <Col>
              <p>Powered by <img src="/eclectec.png" /></p>
            </Col>
          </Row>
          </Form>
        </Container>
    </div>
  );
}

export default App;
